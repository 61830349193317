import React, { useContext } from "react";
import styled from "styled-components";
import queryString from "query-string";
import { LanguageContext } from "../language";
import Failai from "./failai";
import Error from "./error";
import Login from "./login";
import Slaptazodis from "./slaptazodis";
//import IvpSection from "./ivpSection";
import Upload from "./upload";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 80px;
  padding-right: 80px;
  background-color: #fff;
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    margin: 10px 0 0 0;
    padding: 0 10px 10px 10px;
  }
`;

const Block = styled.div`
  //display: flex;
  //flex-direction: column;
  width: 100%;
  @media only screen and (min-height: 991px) {
    min-height: 47vh;
  }
`;

export default function Main() {
  const { strings } = useContext(LanguageContext);
  const parsed = queryString.parse(window.location.search);
  const [error, setError] = React.useState();
  const [failai, setFailai] = React.useState();
  const [galiojaIki, setGaliojaIki] = React.useState();
  const [login, setLogin] = React.useState();
  const [tipas, setTipas] = React.useState();

  const getFailai = async (k) => {
    var url = encodeURI(process.env.REACT_APP_API + "failai/" + k);
    var response = await fetch(url, { method: "GET", credentials: "include" });
    if (response.status === 400) {
      var data = await response.json();
      setError(data.message);
      setFailai();
      setLogin();
      setTipas();
    }
    if (response.status === 200) {
      data = await response.json();
      setFailai(data.failai);
      setGaliojaIki(data.galiojaIki);
      setTipas(data.tipas);
      setError();
      setLogin();
    }
    if (response.status === 401) {
      setLogin(true);
      setFailai();
      setError();
      setTipas();
    }
  };

  const postFailai = async (tekstasIsorinis, f, setIsSaving) => {
    setIsSaving(true);
    var url = process.env.REACT_APP_API + `failai/postFailai/${parsed.kodas}`;
    try {
      var response = await fetch(url, {
        body: JSON.stringify({ tekstas: tekstasIsorinis, failai: f }),
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      if (response.status === 401) {
        setLogin(true);
        setFailai();
        setError();
        return;
      }
      var data = await response.json();
      var pranesimas = strings[data.message] || data.message;
      if (data.b) pranesimas += strings["BlogųFailų"] + data.b;
      if (data.i) pranesimas += strings["GerųFailų"] + data.i;
      setError(pranesimas);
    } catch (error) {
      setError(error);
    } finally {
      setIsSaving(false);
    }
  };

  React.useEffect(() => {
    if (parsed.kodas) {
      getFailai(parsed.kodas);
      return;
    }
    setError("NeteisingasUrl");
  }, [parsed.kodas]);

  if (error)
    return (
      <Block>
        <Error error={error} tipas={tipas} setError={setError} />
        {/* <IvpSection /> */}
      </Block>
    );
  if (tipas && tipas === 2) {
    return (
      <>
        <Container>
          <Block>
            <Upload
              galiojaIki={galiojaIki}
              postFailai={postFailai}
              kodas={parsed.kodas}
            ></Upload>
            <Slaptazodis kodas={parsed.kodas} />
          </Block>
        </Container>
      </>
    );
  }

  if (failai && failai.length) {
    return (
      <Container>
        <Block>
          <Failai
            failai={failai}
            galiojaIki={galiojaIki}
            kodas={parsed.kodas}
            setError={setError}
          ></Failai>
          <Slaptazodis kodas={parsed.kodas} setError={setError} />
        </Block>
      </Container>
    );
  }
  if (login)
    return (
      <Container>
        <Login
          kodas={parsed.kodas}
          setError={setError}
          setFailai={setFailai}
          setLogin={setLogin}
        />
      </Container>
    );
  return null;
}
