import React, { useContext } from 'react'
import styled from "styled-components";
import StyledButton from "./button";
import { StyledPassword } from './styledInput';
import { LanguageContext } from "../language";
import { Grid, Row, Col } from './grid';
import { toast } from 'react-toastify';

const Message = styled.span`
    color: red;
`

export default function Slaptazodis({kodas}) {
    const { strings } = useContext(LanguageContext);
    const [password, setPassword] = React.useState();
    const [confirmPassword, setConfirmPassword] = React.useState();
    const [message, setMessage] = React.useState();

    const handleSubmit = (e) => {
        e.preventDefault();
        if(!password || !confirmPassword) {
            setMessage("SlaptazodisReikalingas");
            return;
        }
        if(password !== confirmPassword) {
            setMessage("SlaptazodziaiNesutampa");
            return;
        }
        setMessage();
        postPassword();
    }

    const handlePassword = (e) => {
        setPassword(e.target.value);
        if(confirmPassword !== e.target.value) {
            setMessage("SlaptazodziaiNesutampa");
        } else {
            setMessage();
        }
    }

    const handleConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
        if(password !== e.target.value) {
            setMessage("SlaptazodziaiNesutampa");
        } else {
            setMessage();
        }
    }

    const postPassword = async () => {
        try {
            var url =
            process.env.REACT_APP_API + `failai/postPassword/${kodas}`;
          var response = await fetch(url, {
            body: JSON.stringify(password),
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            credentials: "include",
          });
          if (response.status === 400) {
              var data = await response.json();
              throw data.message;
          }
          if (response.status === 200) {
              setMessage();
              toast.success(strings["SlaptažodisIšsaugotas"]);
            }
        } catch (error) {
            setMessage(error);
        } 
    };

    return (
    <Grid $marginTop="3rem">
        <form onSubmit={handleSubmit}>
        <Row>
            <Col $size={1}>
                <StyledPassword type="password" value={password} $error={password !== confirmPassword} onChange={handlePassword} placeholder={strings["Slaptažodis"]} />
            </Col>
        </Row>
        <Row>
            <Col $size={1}>
                <StyledPassword type="password" value={confirmPassword} $error={password !== confirmPassword} onChange={handleConfirmPassword} placeholder={strings["PakartokiteSlaptažodį"]}/>

            </Col>
        </Row>
        <Row>
            <Col $size={1}>
                {message && <Message>{strings[message] ?? message}</Message>}
            </Col>
        </Row>        
        <Row>
            <Col>
                <StyledButton type="submit" >{strings["Išsaugoti"]}</StyledButton>
            </Col>
        </Row>
        </form>
    </Grid>
  )
}
