import React, { useContext } from "react";
import styled from "styled-components";
import logo from "../ivp_firminis_spalvotas.png";
import { LocalPhone, Email } from "@emotion-icons/material";
import { LanguageContext } from "../language";
import Logo from "./logo";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 0 80px 0 80px;
  /* background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url("/obuoliai-narrow.jpg"); */

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media only screen and (max-width: 575px) {
    background-image: url('/APKL_575x250.jpg');
  }
  @media only screen and (min-width: 576px) {
    background-image: url('/APKL_767x243.jpg');
  }
  @media only screen and (min-width: 768px) {
    background-image: url('/APKL_991x266.jpg');
  }
  @media only screen and (min-width: 992px) {
    background-image: url('/APKL_1199x300.jpg');
  }
  @media only screen and (min-width: 1200px) {
    background-image: url('/APKL_1407x310.jpg');
  }
  @media only screen and (min-width: 1400px) {
    background-image: url('/APKL_1920x320.jpg');
    height: 340px;
  }
`;

const HeaderTop = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    margin: 10px 0 0 0;
    padding: 0 10px 10px 10px;
  }
`;

const HeaderMiddle = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 200px;
  height: auto;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 576px) {
    margin-top: 10px;
  }
  @media only screen and (min-width: 768px) {
    margin-top: 10px;
  }
  @media only screen and (min-width: 992px) {
    margin-top: 10px;
  }
  @media only screen and (min-width: 1200px) {
    margin-top: 10px;
  }
  @media only screen and (min-width: 1400px) {
    margin-top: 0px;
  }

  `;

const HeaderText = styled.div`
  margin-top: 10px;
  color: #fff;
  font-size: 14px;
  font-family: system-ui, sans-serif;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
  @media only screen and (min-width: 576px) {
    margin: 10px 20px;
  }
  @media only screen and (min-width: 768px) {
    margin: 10px 40px;
  }
  @media only screen and (min-width: 992px) {
    margin: 10px 50px;
  }
  @media only screen and (min-width: 1200px) {
    margin: 10px 60px;
  }
  @media only screen and (min-width: 1400px) {
    margin: 10px 80px;
  }
`;

const Heading = styled.div`
  color: #fff;
  text-align: center;
  font-size: 2em;
  font-weight: 400;

  @media only screen and (max-width: 575px) {
    font-size: 7vw;
    letter-spacing: 1px;
  }
  @media only screen and (min-width: 576px) {
    font-size: 4vw;
    letter-spacing: 1px;
  }
  @media only screen and (min-width: 768px) {
    font-size: 3vw;
    letter-spacing: 1px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 3vw;
    letter-spacing: 6px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 3vw;
    letter-spacing: 8px;
  }
  @media only screen and (min-width: 1400px) {
    font-size: 3vw;
    letter-spacing: 16px;
  }
`;

const LanguageBtn = styled.button`
  border: none;
  color: ${props => props.active ? "#cca876;" : "#9b9b9b;"};
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  height: 14px;
  width: 20px;
  &:hover {
    opacity: 80%;
  }
`;

export default function Header() {
  const { language, strings, updateLanguage } = useContext(LanguageContext);
  return (
    <Container>
      <HeaderTop>
        <Logo src={logo}></Logo>
        <HeaderText>
          <LocalPhone size={20} color="#cca876" /> (+370 5) 2197601
        </HeaderText>
        <HeaderText>
          <Email size={20} color="#cca876" /> info@ivp.lt
        </HeaderText>
        <HeaderText>
          <LanguageBtn
            src={language === "lt" ? "/en.png" : "/lt.png"}
            onClick={() => updateLanguage(language === "lt" ? "en" : "lt")}
            title={language === "lt" ? "Switch to English" : "Perjungti į Lietuvių kalbą"}
          />
        </HeaderText>
      </HeaderTop>
      <HeaderMiddle>
        <Heading>{strings["APSIKEITIMO DOKUMENTAIS SISTEMA"]} </Heading>
      </HeaderMiddle>
    </Container>
  );
}
